import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';
import css from './InquiryModal.module.css';
import basedPostUrlRequestLogedIn from '../../../util/apiRequests/basedPostUrlRequestLogedIn';
import DatePickerComponent from './DatePickerComponent/DatePickerComponent';

const formatDate = value => {
  if (!value) return '';
  const [year, month, day] = value.split('-');
  return `${day}/${month}/${year}`;
};

const parseDate = value => {
  if (!value) return '';
  const [day, month, year] = value.split('/');
  return `${year}-${month}-${day}`;
};

const isFutureDate = date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date >= today;
};

const InquiryModal = ({ isOpen, onClose, onCloseInquirySend }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const intl = useIntl();

  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [dateErrors, setDateErrors] = useState({ startDate: false, endDate: false });

  const onSubmit = async values => {
    try {
      const payload = { ...values, startDate: dates.startDate, endDate: dates.endDate };
      const response = await basedPostUrlRequestLogedIn('/api/inquiry', payload);
      onCloseInquirySend();
      if (response && !response.error) {
        setSuccessMessage(intl.formatMessage({ id: 'InquiryModal.successMessage' }));
        setIsError(false);
        setHideForm(true);

        setTimeout(() => {
          onClose();
        }, 10000);
      } else {
        const errorMessage =
          response?.message || intl.formatMessage({ id: 'InquiryModal.errorMessage' });
        setStatusMessage(errorMessage);
        setIsError(true);
      }
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setStatusMessage(intl.formatMessage({ id: 'InquiryModal.unexpectedError' }));
      setIsError(true);
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.topic) {
      errors.topic = intl.formatMessage({ id: 'InquiryModal.topicRequired' });
    }
    if (!values.email) {
      errors.email = intl.formatMessage({ id: 'InquiryModal.emailRequired' });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = intl.formatMessage({ id: 'InquiryModal.invalidEmail' });
    }
    if (!values.phone) {
      errors.phone = intl.formatMessage({ id: 'InquiryModal.phoneRequired' });
    } else if (!/^\+?[1-9]\d{1,14}$/i.test(values.phone)) {
      errors.phone = intl.formatMessage({ id: 'InquiryModal.invalidPhone' });
    }
    if (!values.postCode) {
      errors.postCode = intl.formatMessage({ id: 'InquiryModal.postCodeRequired' });
    } else if (!/^\d{4,6}$/i.test(values.postCode)) {
      errors.postCode = intl.formatMessage({ id: 'InquiryModal.invalidPostCode' });
    }
    if (!values.message) {
      errors.message = intl.formatMessage({ id: 'InquiryModal.messageRequired' });
    }

    // Date validation
    if (!dates.startDate || !isFutureDate(new Date(dates.startDate))) {
      errors.startDate = intl.formatMessage({ id: 'InquiryModal.invalidStartDate' });
    }
    if (!dates.endDate || !isFutureDate(new Date(dates.endDate))) {
      errors.endDate = intl.formatMessage({ id: 'InquiryModal.invalidEndDate' });
    } else if (
      dates.startDate &&
      dates.endDate &&
      new Date(dates.startDate) > new Date(dates.endDate)
    ) {
      errors.endDate = intl.formatMessage({ id: 'InquiryModal.endDateBeforeStartDate' });
    }

    return errors;
  };

  const handleDateChange = (field, date) => {
    setDates(prevDates => ({ ...prevDates, [field]: date }));
    setDateErrors(prevErrors => ({ ...prevErrors, [field]: true }));
  };

  if (!isOpen) return null;

  const modalClasses = classNames(css.modalBackdrop, css.open);

  return (
    <div className={modalClasses}>
      <div className={css.modalContent}>
        <div className={css.secondContainer}>
          <div className={css.formClosing}>
            <h2 className={css.title}>
              {!hideForm && <FormattedMessage id="InquiryModal.heading" />}
            </h2>
            <button className={css.closeButton} onClick={onClose}>
              &times;
            </button>
          </div>

          <FinalForm
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, errors, values }) => (
              <Form className={css.form} onSubmit={handleSubmit}>
                {/* {JSON.stringify(values)} */}
                {!hideForm && (
                  <>
                    <FieldTextInput
                      className={css.field}
                      type="text"
                      id="topic"
                      name="topic"
                      label={<FormattedMessage id="InquiryModal.topicLabel" />}
                      placeholder={intl.formatMessage({ id: 'InquiryModal.topicLabel' })}
                    />
                    <FieldTextInput
                      className={css.field}
                      type="email"
                      id="email"
                      name="email"
                      label={<FormattedMessage id="InquiryModal.emailLabel" />}
                      placeholder={intl.formatMessage({ id: 'InquiryModal.emailLabel' })}
                    />
                    <FieldTextInput
                      className={css.field}
                      type="text"
                      id="phone"
                      name="phone"
                      label={<FormattedMessage id="InquiryModal.phoneLabel" />}
                      placeholder={intl.formatMessage({ id: 'InquiryModal.phonePlaceholder' })}
                    />
                    <FieldTextInput
                      className={css.field}
                      type="text"
                      id="postCode"
                      name="postCode"
                      label={<FormattedMessage id="InquiryModal.postCodeLabel" />}
                      placeholder={intl.formatMessage({ id: 'InquiryModal.postCodePlaceholder' })}
                    />
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="startDate">
                          <FormattedMessage id="InquiryModal.startDateLabel" />
                        </label>
                        <DatePickerComponent
                          id="startDate"
                          intl={intl}
                          onChange={date => handleDateChange('startDate', date)}
                        />
                        {dateErrors.startDate && errors.startDate && (
                          <span className={css.error}>{errors.startDate}</span>
                        )}
                      </div>
                      <div className={css.field}>
                        <label htmlFor="endDate">
                          <FormattedMessage id="InquiryModal.endDateLabel" />
                        </label>
                        <DatePickerComponent
                          intl={intl}
                          id="endDate"
                          onChange={date => handleDateChange('endDate', date)}
                        />
                        {dateErrors.endDate && errors.endDate && (
                          <span className={css.error}>{errors.endDate}</span>
                        )}
                      </div>
                    </div>
                    <FieldTextInput
                      className={css.field}
                      type="textarea"
                      id="message"
                      name="message"
                      label={<FormattedMessage id="InquiryModal.messageLabel" />}
                      placeholder={intl.formatMessage({ id: 'InquiryModal.messageLabel' })}
                    />

                    <PrimaryButton
                      type="submit"
                      className={css.submitButton}
                      inProgress={submitting}
                      disabled={submitting || !values.subscribe}
                    >
                      <FormattedMessage id="InquiryModal.submitButton" />
                    </PrimaryButton>
                    <div className={css.checkboxContainer}>
                      <label className={css.checkboxLabel}>
                        <Field
                          name="subscribe"
                          component="input"
                          type="checkbox"
                          className={css.checkbox}
                        />
                        <FormattedMessage id="InquiryModal.subscribeLabel" />
                      </label>
                    </div>
                    <p className={css.smallText}>
                      <FormattedMessage id="InquiryModal.smallText" />
                    </p>
                  </>
                )}
                {successMessage && (
                  <p className={classNames(css.statusMessage, css.successMessage)}>
                    {successMessage}
                  </p>
                )}
                {statusMessage && (
                  <p
                    className={classNames(css.statusMessage, {
                      [css.errorMessage]: isError,
                      [css.successMessage]: !isError,
                    })}
                  >
                    {statusMessage}
                  </p>
                )}
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default InquiryModal;
